import { TbStar, TbStarFilled, TbStarHalfFilled } from "react-icons/tb";

const renderStars = (rating: number, size: number) => {
	const stars = [];
	for (let i = 1; i <= 5; i++) {
		if (i <= rating) {
			stars.push(<TbStarFilled key={i} size={size} />);
		} else if (i - 0.5 <= rating) {
			stars.push(<TbStarHalfFilled key={i} size={size} />);
		} else {
			stars.push(<TbStar key={i} size={size} />);
		}
	}
	return stars;
};

type Props = {
	rating: number;
	size?: number;
};

export const RatingStars = ({ rating, size = 20 }: Props) => {
	return <div className="flex text-[#F4B400]">{renderStars(rating, size)}</div>;
};
