"use client";

import { protos } from "@googlemaps/places";
import { Card } from "@nextui-org/card";
import Image from "next/image";
import { z } from "zod";
import { RatingStars } from "./RatingStars";
import GoogleGLogo from "./assets/google-g-logo.svg";
import { useEffect, useRef, useState } from "react";
import { cx } from "class-variance-authority";

const Review = z.object({
	authorAttribution: z.object({
		displayName: z.string(),
		photoUri: z.string(),
	}),
	relativePublishTimeDescription: z.string(),
	text: z.object({
		text: z.string(),
	}),
	rating: z.number(),
});

type Props = {
	review: protos.google.maps.places.v1.IReview;
};

export const ReviewCard = ({ review: unvalidatedReview }: Props) => {
	const review = Review.parse(unvalidatedReview);

	const [isExpanded, setIsExpanded] = useState(false);
	const [isClamped, setIsClamped] = useState(false);
	const textRef = useRef<HTMLParagraphElement>(null);

	// Detect if text is truncated
	useEffect(() => {
		if (textRef.current) {
			setIsClamped(textRef.current.scrollHeight > textRef.current.clientHeight);
		}
	}, []);

	return (
		<Card className="text-small">
			<div className="flex h-full flex-col gap-4 p-4">
				<div className="flex gap-x-2">
					<Image
						src={review.authorAttribution.photoUri}
						alt={review.authorAttribution.displayName}
						width={40}
						height={40}
					/>

					<div className="flex flex-col justify-between">
						<p className="font-bold">{review.authorAttribution.displayName}</p>
						<p className="text-foreground-500">
							{review.relativePublishTimeDescription}
						</p>
					</div>
				</div>

				<div className="flex-grow">
					<p
						ref={textRef}
						className={cx("whitespace-pre-wrap", {
							"line-clamp-6": !isExpanded,
						})}
					>
						{review.text.text}
					</p>

					{isClamped && (
						<button
							onClick={() => setIsExpanded(!isExpanded)}
							className="mt-1 text-foreground-500 hover:underline"
						>
							{isExpanded ? "Read less" : "Read more"}
						</button>
					)}
				</div>

				<div className="flex items-end justify-between">
					<RatingStars rating={review.rating} />

					<GoogleGLogo className="h-8 w-8" />
				</div>
			</div>
		</Card>
	);
};
